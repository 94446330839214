import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import TextArea from './TextArea';
import Box from "@material-ui/core/Box";
import label from 'src/utils/label';
import { green, red, yellow, lime, orange, grey } from "@material-ui/core/colors";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";


const useStyles = makeStyles({
    group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
    }
});

const RedSentimentVeryDissatisfiedIcon = withStyles({
    root: {
        color: red[600],
        fontSize: 30
    }
})((props) => <SentimentVeryDissatisfiedIcon {...props} />);

const OrangeSentimentDissatisfiedIcon = withStyles({
    root: {
        color: orange[600],
        fontSize: 30
    }
})((props) => <SentimentDissatisfiedIcon {...props} />);

const YellowSentimentSatisfiedIcon = withStyles({
    root: {
        color: yellow[600],
        fontSize: 30
    }
})((props) => <SentimentSatisfiedIcon {...props} />);

const LimeSentimentSatisfiedAltIcon = withStyles({
    root: {
        color: lime[600],
        fontSize: 30
    }
})((props) => <SentimentSatisfiedAltIcon {...props} />);

const GreenSentimentVerySatisfiedIcon = withStyles({
    root: {
        color: green[600],
        fontSize: 30
    }
})((props) => <SentimentVerySatisfiedIcon {...props} />);

const getIcon =(index) => {
    var result;
    switch (index) {
        case 0:
            result = <GreenSentimentVerySatisfiedIcon />
            break;
        case 1:
            result = <LimeSentimentSatisfiedAltIcon />
            break;
        case 2:
            result = <YellowSentimentSatisfiedIcon />
            break;
        case 3:
            result = <OrangeSentimentDissatisfiedIcon />
            break;
        case 4:
            result = <RedSentimentVeryDissatisfiedIcon />
            break;
        default:
            result = null;
            break;

    }
    return (result)
};

const Radios = ({ ro, isComments, survey, getComments, isChecked, handleChange, questionId, answers }) => {
    const classes = useStyles();
    let smileyQuestion = false;
    let smileyQuestions = [7384,7385,7386,7387,7388,7389,7390,7391,7392,7393,7394,7395,7396,7397,7398,7399,7400,7401,7402,7403,7404,7405,7406,7407,7408]
    if([155,156,157,158,159,160,161,162].includes(survey) && smileyQuestions.includes(questionId) && ![7275, 7370, 7371, 7372, 7373, 7374].includes(questionId)) {
        smileyQuestion = true;
    }
    const SmileyRadio = withStyles({
        root: {
            "&$checked": {
                backgroundColor: grey[100]
            },
        },
        checked: {}
    })((props) => <Radio color= "default" {...props} checkedIcon={getIcon(props.index)} icon={getIcon(props.index)} />);
    
    const GreenRadio = withStyles({
        root: {
            color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[400],
            "&$checked": {
                color: survey === 118 ? 'blue' : survey === 119 ? '#ffaa01' : green[600]
            },
        },
        checked: {}
    })((props) => <Radio color= "default" {...props} />);

    const answersList = answers.map((answer, index) => {
        if (isComments) {
            if (index < (answers.length - 1)) {
                return (
                    <FormControlLabel
                        style={{ paddingTop: '7px' }}
                        key={answer.id}
                        value={questionId + '_' + answer.id}
                        control={<GreenRadio size="small" />}
                        label={label(answer.label)}
                        labelPlacement="end"
                        onChange={handleChange}
                        checked={isChecked(questionId, answer.id)}
                    />
                )
            }
            if (index === (answers.length - 1)) {
                return <TextArea ro={ro} survey={survey} getComments={getComments} key={answer.id} handleChange={handleChange} questionId={questionId} answer={answer} />;
            }
        } else {
            return (
                <FormControlLabel
                    style={{ paddingTop: '7px' }}
                    key={answer.id}
                    value={questionId + '_' + answer.id}
                    control={!!smileyQuestion ? <SmileyRadio index={index} size="small" /> : <GreenRadio size="small" />}
                    label={label(answer.label)}
                    labelPlacement="end"
                    onChange={handleChange}
                    checked={isChecked(questionId, answer.id)}
                />
            )
        }
        return null;
    });

    return (
        <Box display="flex" alignItems="left" justifyContent="left" flexDirection="column">
            <FormControl disabled={JSON.parse(ro)} component="fieldset">
                <RadioGroup className={classes.group} aria-label={'q' + questionId} name={'q' + questionId} >
                    {answersList}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default Radios;